<template>
  <div>
    <ClientPackagesList :packages="generatedPackages" :loading="getPackagesInProgress">
      <template #title>
        <div class="d-flex justify-space-between align-center">
          <span class="text-h6"> Package generation </span>
        </div>
      </template>
      <template #desc>
        <p>
          By default, Patriot will generate draft packages. Once the draft is accepted, enable
          <b>Final</b> below to generate a final package.
        </p>
      </template>
      <template #delete-button="{packageId}">
        <v-btn
            :loading="deleteInProgress"
            class="ml-2"
            type="button"
            @click="deletePackage(packageId)"
            icon
        >
          <v-icon> mdi-close</v-icon>
        </v-btn>
      </template>
      <template #empty-text>
        <p class="text-caption">NO PACKAGES FOUND</p>
      </template>
      <template #actions>
        <div v-if="isAdmin" class="d-flex justify-end align-end mb-3 mr-2">
          <v-spacer></v-spacer>
          <div class="d-flex align-center">
            <v-switch
              v-model="isDraft"
              label="Final"
              color="red"
              class="switchDraftOrFinal my-0 mr-3"
              hide-details
            />
            <v-btn
              id="generatePackageBtn"
              color="primary"
              @click.native="generatePackage"
              :loading="generationInProgress"
              :disabled="generatePackageButtonDisabled"
            >
              GENERATE PACKAGE
            </v-btn>
          </div>
        </div>
      </template>
    </ClientPackagesList>
    <br>
    <ClientPackagesList :packages="type2020supplements" :loading="getPackagesInProgress">
      <template #title>
        <div class="d-flex justify-space-between align-center">
          <span class="text-h6">2020 Supplements</span>
        </div>
      </template>
      <template #delete-button="{packageId}">
        <v-btn
            :loading="deleteInProgress"
            class="ml-2"
            type="button"
            @click="deletePackage(packageId)"
            icon
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <template #empty-text>
        <p class="text-caption">NO PACKAGES FOUND</p>
      </template>
    </ClientPackagesList>
    <br>
    <ClientPackagesList :packages="invoices" :loading="getPackagesInProgress">
      <template #title>
        <div class="d-flex justify-space-between align-center">
          <span class="text-h6">Invoices</span>
        </div>
      </template>
      <template #delete-button="{packageId}">

      </template>
      <template #empty-text>
        <p class="text-caption">NO PACKAGES FOUND</p>

      </template>
      <template #actions>
        <v-btn
            class="ml-2 rounded primary"
            type="button"
            :loading="invoiceLoading"
            @click="generateInvoice"
        >
          Generate Invoice
        </v-btn>
      </template>
    </ClientPackagesList>
    <br />
    <div class="bordered pa-5">
      <h2 class="text-h6 mb-8">8821 Blank Form</h2>
      <v-btn
          class="ml-2 rounded primary"
          :loading="invoiceLoading"
          @click="generateBlankForm"
      >Generate</v-btn>
    </div>
    <div>

    </div>
<!--    <br>-->
  </div>

</template>

<script>
import PackageService from "@/services/packages.service";
import ClientService from "@/services/clients.service";
import ClientPackagesList from "@/components/Client/Profile/ClientPackagesList.vue";
import {PDFDocument, rgb} from "pdf-lib";

export default {
  name: "ClientPackages",
  components: {
    ClientPackagesList,
  },
  props: {
    clientId: {
      required: true,
    },
    client: {
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
    generatePackageButtonDisabled: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    generationInProgress: false,
    deleteInProgress: false,
    getPackagesInProgress: false,
    isDraft: false,
    generatedPackages: [],
    type2020supplements: [],
    invoices: [],
    type4506files: [],
    type4506Loading: false,
    generation4506inProgress: false,
    invoiceLoading: false,
  }),
  methods: {
    async generateBlankForm(){
      this.invoiceLoading = true
      try {
        const response = await fetch('/f8821.pdf')
        const pdfBytes = await response.arrayBuffer()
        const pdfDoc = await PDFDocument.load(pdfBytes)

        const newPdfDoc = await PDFDocument.create()
        const [copiedPage] = await newPdfDoc.copyPages(pdfDoc, [0])
        newPdfDoc.addPage(copiedPage)

        const page = newPdfDoc.getPages()[0]
        page.drawText(this.client.legal_name + ',', {
          x: 40,
          y: 660,
          size: 9
        })
        page.drawText(this.client.city + ', ' + this.client.street, {
          x: 40,
          y: 650,
          size: 9
        })
        page.drawText(this.client.state + ', ' + this.client.zip , {
          x: 40,
          y: 640,
          size: 9,
        })
        page.drawText(this.client.contact_phone , {
          x: 370,
          y: 640,
          size: 9,
        })
        page.drawText(this.client.ein , {
          x: 370,
          y: 663,
          size: 9,
        })

        const modifiedPdfBytes = await newPdfDoc.save()
        const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' })
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = this.client.legal_name + '_8821.pdf'
        link.click()
      }finally {
        this.invoiceLoading = false
      }
    },
    generateInvoice(){
      this.invoiceLoading = true
      PackageService.generateInvoice(this.clientId).then((data) => {
        this.getInvoices()
      }).finally(() => {
        this.invoiceLoading = false
      });
    },
    getInvoices(){
      PackageService.getInvoices(this.clientId).then((data) => {
        this.invoices = data;
      });
    },
    getAllPackages() {
      this.getPackagesInProgress = true;
      this.type2020supplements = [];
      this.generatedPackages = [];
      return PackageService.getPackages(this.clientId)
        .then((data) => {
          if (data) {
            data.forEach(e => {
              if (e.status === '2020-supplement')
                this.type2020supplements.push(e)
              else
                this.generatedPackages.push(e)
            })
          }
        })
        .finally(() => (this.getPackagesInProgress = false));
    },
    deletePackage(id) {
      this.deleteInProgress = true;
      return PackageService.deletePackage(id)
        .then((res) => {
          this.getAllPackages();
          this.$store.dispatch("snackbar/showSnackbarMessage", {
            message: `Package was deleted successfully.`,
            duration: 4000,
            mode: "success",
          });
        })
        .finally(() => (this.deleteInProgress = false));
    },
    generatePackage() {
      let typeGeneration = this.isDraft ? "final" : "draft";

      this.generationInProgress = true;
      this.$store.dispatch("snackbar/showSnackbarMessage", {
        message: `Package generation scheduled successfully.`,
        duration: 4000,
        mode: "success",
      });
      ClientService.postPackage(this.clientId, { request: typeGeneration })
        .then((res) => {
          this.getAllPackages();
          this.generationInProgress = false;
        })
        .finally(() => (this.generationInProgress = false));
    }
  },
  async created() {
    this.getAllPackages();
    this.getInvoices();
  },
};
</script>

<style scoped>
  .bordered {
    border: 1px solid rgba(0, 0, 0, 0.13);
  }
</style>
